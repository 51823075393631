


































import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { User } from "@/models/case-maintenance.d";
import AuthService from "@/services/auth-service";
import SelectWithStatus from "@/components/material/select-with-status.vue";
import { CaseView } from "@/models/case-maintenance";

@Component({
  components: {
    SelectWithStatus
  },
  inheritAttrs: false
})
export default class Control extends Vue {
  @Inject() AuthService!: AuthService;

  @Prop({ default: () => [] }) users!: User[];

  @Prop({ required: true }) caseId!: CaseView | null;
  @Prop({ default: null }) selectedUserId!: CaseView | null;

  @Prop({ default: () => false }) disabled!: boolean;

  self = null as User | null;

  get allUsers() {
    const self = this.self ? [this.self] : [];
    const unassign = [{ id: null, name: "<Unassigned>" }];
    return [...unassign, ...self, ...this.users.filter(x => x.id !== this.self?.id)];
  }

  async created() {
    const user = await this.AuthService.getUser();
    if (user) {
      this.self = {
        id: user.profile.sub,
        name: user.profile.name ?? "Me"
      };
    } else {
      this.self = null;
    }
  }
}
