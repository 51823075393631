
































































import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class CloseCaseDialog extends Vue {
  @PropSync('show', { required: true }) syncedShow!: boolean;

  reason = '';
  note = '';

  items = [
    'No action required',
    'Queries answered',
    'Awaiting resubmission',
  ]

  cancel() {
    this.syncedShow = false;
    this.$emit('cancel');
    this.reason = '';
    this.note = '';
  }

  closeCase() {
    this.syncedShow = false;
    this.$emit('close-case', this.reason, this.note);
    this.reason = '';
    this.note = '';
  }
}
